import "../scss/index.scss";

const TO_RADIANS = Math.PI / 180;

let flamesCanvas = document.querySelector(".flames");
let flamesContext = flamesCanvas.getContext('2d');
flamesCanvas.height = 200;

let confettiCanvas = document.querySelector(".confetti");
let confettiContext = confettiCanvas.getContext('2d');
confettiCanvas.width = window.innerWidth;
confettiCanvas.height = window.innerHeight;

let balloonsCanvas = document.querySelector(".balloons");
let balloonsContext = balloonsCanvas.getContext('2d');
balloonsCanvas.width = window.innerWidth;
balloonsCanvas.height = window.innerHeight;

let sparklerCanvas = document.querySelector(".sparkler");
let sparklerContext = sparklerCanvas.getContext('2d');
sparklerCanvas.width = window.innerWidth;
sparklerCanvas.height = 200;

// SET FLAME GRADIENT
var flameGradient = flamesContext.createLinearGradient(0, 170, 0, 190);
flameGradient.addColorStop(0, "rgba(255, 160, 100, 0.75)");
flameGradient.addColorStop(0.66, "rgba(225, 200, 150, 0.5)");
flameGradient.addColorStop(1, "rgba(50, 200, 255, 0.15)");
flamesContext.fillStyle = flameGradient;

// CREATE BALLLON ART IN MEMORY
let balloonShapeCanvas = document.createElement("canvas");
balloonShapeCanvas.width = 110;
balloonShapeCanvas.height = 95;
let balloonShapeContext = balloonShapeCanvas.getContext("2d");
balloonShapeContext.strokeStyle = "rgba(255,255,255,0.1)";
var balloonGradient = balloonShapeContext.createLinearGradient(0, balloonShapeCanvas.width, 0, balloonShapeCanvas.height * 0.8);
balloonGradient.addColorStop(0, "rgba(0, 0, 0, 0.2)");
balloonGradient.addColorStop(1, "rgba(255,255,255,0.2)");
balloonShapeContext.translate(-20, -25)
balloonShapeContext.fillStyle = "rgba(255, 100, 100, 0.99)"
balloonShapeContext.beginPath();
balloonShapeContext.moveTo(75, 40);
balloonShapeContext.bezierCurveTo(75, 37, 70, 25, 50, 25);
balloonShapeContext.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
balloonShapeContext.bezierCurveTo(20, 80, 40, 102, 75, 120);
balloonShapeContext.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
balloonShapeContext.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
balloonShapeContext.bezierCurveTo(85, 25, 75, 37, 75, 40);
balloonShapeContext.fill();
balloonShapeContext.stroke();
balloonShapeContext.fillStyle = balloonGradient;
balloonShapeContext.fill();
// document.body.appendChild(balloonShapeCanvas)

sparklerContext.fillStyle = "rgba(230, 210, 150, 0.75)"

let blobs = [];
let sparks = [];
let confetti = [];
let balloons = [];
let distortion = 0;
let distortionMax = 0;
let started = false;
let candlesLit = true;
let smoking = true;

let darkness = document.querySelector(".darkness");
let message = document.querySelector(".message");
let lights = document.querySelector(".light-wrapper");
let bgm = document.querySelector(".bgm");




// let sparklerSound = document.querySelector(".sparkler-sound");
// sparklerSound.volume = 0.5;
// sparklerSound.loop = true;

function randint(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randfloat(min, max) {
    return Math.random() * (max - min) + min;
}

function rotateAndPaint(context, image, angleInRad, positionX, positionY, axisX, axisY) {
    context.translate(positionX, positionY);
    context.rotate(angleInRad);
    context.drawImage(image, -axisX, -axisY);
    context.rotate(-angleInRad);
    context.translate(-positionX, -positionY);
}

function createBlobs() {
    let offset = 0;
    if (Math.random() < 0.01 && candlesLit) {
        offset = randfloat(-1, 1);
    } else if (!candlesLit) {
        offset = randfloat(-5, 5)
    }

    let blob = { x: flamesCanvas.width / 2 - 16.75 + offset + (randfloat(-2, 2) * distortion), y: flamesCanvas.height - 10 }
    blobs.push(blob);

    if (offset !== 0) {
        offset = candlesLit ? randfloat(-1, 1) : randfloat(-5, 5);
    }
    blob = { x: flamesCanvas.width / 2 + 24.75 + offset + (randfloat(-2, 2) * distortion), y: flamesCanvas.height - 10 }
    blobs.push(blob);

}


function createSparks() {

    for (let i = 0; i < 5; i++) {
        let spark = {
            x: sparklerCanvas.width / 2 + 86,
            y: sparklerCanvas.height - 117,
            dx: randfloat(-2, 2),
            dy: randfloat(-4, 2),
            age: 0
        }
        sparks.push(spark);

        spark = {
            x: sparklerCanvas.width / 2 + 108,
            y: sparklerCanvas.height - 104,
            dx: randfloat(-2, 2),
            dy: randfloat(-4, 2),
            age: 0
        }
        sparks.push(spark);
    }

}

function createConfetti() {
    for (let i = 750; i--;) {
        let leftSide = Math.random() < 0.5;
        confetti.push({
            x: leftSide ? 30 : confettiCanvas.width - 30,
            y: confettiCanvas.height,
            color: `hsl(${randint(1, 359)}, 66%, 63%)`,
            dx: leftSide ? randfloat(2, 4) : randfloat(-4, -2),
            dy: randfloat(-20, -15),
            dyLimit: randfloat(-4, -1),
            size: randint(1, 2),
            yLimit: (confettiCanvas.height - 10) * randfloat(0.95, 1)
        })
    }
}

function createBalloons() {
    for (let i = 0; i < 1; i++) {
        balloons.push({
            x: balloonsCanvas.width / 2,
            y: balloonsCanvas.height - 200,
            dx: randint(-2, 2),
            dy: 1
        })
    }
}

function handleAcceleration() {
    // distortion += acceleration.x
    // document.querySelector(".accel-debug").innerHTML = `X: ${acceleration.x.toFixed(2)}, Y: ${acceleration.y.toFixed(2)}, Z: ${acceleration.z.toFixed(2)}, distortion: ${distortion} `;
}

function handleGravity() {
    document.querySelector(".gravity-debug").innerHTML = `Grav: ${gravity.x.toFixed(2)}` //`X: ${gravity.x.toFixed(2)}, Y: ${gravity.y.toFixed(2)}, Z: ${gravity.z.toFixed(2)} `;
}

function handleGyroscope() {
    document.querySelector(".gyro-debug").innerHTML = `X: ${gyroscope.x.toFixed(2)}, Y: ${gyroscope.y.toFixed(2)}, Z: ${gyroscope.z.toFixed(2)} `;
}

function render() {

    let currentBlobs = [];
    let currentSparks = [];
    flamesContext.clearRect(0, 0, flamesCanvas.width, flamesCanvas.height);
    confettiContext.clearRect(0, 0, confettiCanvas.width, confettiCanvas.height);
    balloonsContext.clearRect(0, 0, balloonsCanvas.width, balloonsCanvas.height);
    sparklerContext.clearRect(0, 0, sparklerCanvas.width, confettiCanvas.height);

    if (distortion >= 5 && candlesLit && started) {
        candlesLit = false;
        createConfetti();
        createBalloons();
        bgm.play();
        lights.classList.add("light-wrapper--on");
        message.style.textAlign = "justify";
        message.innerHTML = "<p>Cody Love, I hope you have a wonderful 31<sup style='font-size: 1rem'>st</sup> birthday!</p><p>You're an amazing, kind, incredible person and I hope you know how much you mean to me.</p><p>I love you!<br>- Jeffrey</p>"

        // window.setTimeout(() => { smoking = false; }, 5000)
    }

    createSparks();

    if (candlesLit || smoking) {
        createBlobs();
    }

    for (let blob of blobs) {

        let radius = candlesLit ? Math.min((blob.y - 170) / 4, 3) : Math.min((blob.y * 0.01), 4);

        if (!candlesLit) {
            flamesContext.fillStyle = `rgba(200,200,200,${(blob.y * 0.1) / 170})`
        }

        flamesContext.beginPath()
        flamesContext.arc(blob.x, blob.y, radius + randint(0, 1), 0, 2 * Math.PI);
        flamesContext.fill();


        blob.x += Math.max(Math.min(acceleration.x * -1, 1), -1) + (gravity.x / 9.8);
        blob.y -= 1;

        if ((candlesLit && blob.y > 170) || (!candlesLit && blob.y > 50)) {
            currentBlobs.push(blob);
        }

    }
    blobs = currentBlobs;

    for (let spark of sparks) {

        spark.x += spark.dx;
        spark.y += spark.dy;

        sparklerContext.beginPath()
        sparklerContext.arc(spark.x, spark.y, 1, 0, 2 * Math.PI);
        sparklerContext.fill();

        spark.dx -= gravity.x * 0.03;
        spark.dy += gravity.y * 0.05;

        spark.dx *= 0.99
        spark.dy *= 0.99;

        if (distortion > 2) {
            spark.dx += distortion * randfloat(-0.5, 0.5);
            spark.dy += distortion * randfloat(-0.5, 0.5);
        }

        spark.age++;

        if (spark.age < 10) {
            currentSparks.push(spark);
        }

    }
    sparks = currentSparks;

    for (let bit of confetti) {

        confettiContext.fillStyle = Math.random() < 0.01 ? "#ffffff" : bit.color;

        bit.x += bit.dx;
        bit.y += bit.dy;

        if (bit.x < bit.size + 10) { bit.dx = -bit.dx; bit.x += bit.size + 2; }
        if (bit.x > confettiCanvas.width - bit.size - 10) { bit.dx = -bit.dx; bit.x -= bit.size + 2; }
        if (bit.y < bit.size + 10) { bit.dy = -bit.dy; bit.y += bit.size + 2; }
        if (bit.y > bit.yLimit) { bit.dy = -bit.dy; bit.y = bit.yLimit; }

        confettiContext.beginPath();
        // confettiContext.fillRect(bit.x - bit.size / 2, bit.y - bit.size / 2, bit.size, bit.size);
        confettiContext.arc(bit.x - bit.size / 2, bit.y - bit.size / 2, bit.size, 0, 2 * Math.PI);
        confettiContext.fill();

        bit.dx *= 0.985;
        bit.dy *= 0.985;

        if (Math.abs(gravity.x) > 1) { bit.dx -= gravity.x * 0.02; }
        if (Math.abs(gravity.y) > 1) { bit.dy += gravity.y * 0.02; }

        if (Math.abs(acceleration.x) > 2) { bit.dx -= acceleration.x * 0.1; }
        if (Math.abs(acceleration.y) > 2) { bit.dy += acceleration.y * 0.1; }

        if (distortion > 2) {
            bit.dx +=  distortion * randfloat(-0.1, 0.1);
            bit.dy += distortion * randfloat(-0.3, -0.03);
        }

        bit.dx += randfloat(-0.25, 0.25);
        bit.dy += randfloat(-0.25, 0.25)

        if (bit.dy < -100) bit.dy = -100;
        if (bit.dy > 100) bit.dy = 100;

        // if (bit.dy < bit.dyLimit) bit.dy = bit.dyLimit;
        // if (bit.dy > 1) bit.dy = randfloat(0.8, 1.2);

    }

    // balloonsContext.beginPath();
    // balloonsContext.arc(balloonsCanvas.width / 2, 200, 100, 0, 2*Math.PI);
    // balloonsContext.fill();
    // balloonsContext.fillStyle = balloonGradient;
    // balloonsContext.fill();

    for (let balloon of balloons) {
        let angle = 45 * (gravity.x / 9.8);
        rotateAndPaint(balloonsContext, balloonShapeCanvas, angle * TO_RADIANS, balloon.x, balloon.y, 55, 47.5)

        balloon.x += balloon.dx;
        balloon.y += balloon.dy;

        balloon.dx *= 0.97;
        balloon.dy *= 0.97;

        if (balloon.x < 65) { balloon.dx = -balloon.dx; balloon.x = 65; }
        if (balloon.x > balloonsCanvas.width - 65) { balloon.dx = -balloon.dx; balloon.x = balloonsCanvas.width - 65; }
        if (balloon.y < 57.5) { balloon.dy = -balloon.dy; balloon.y = 57.5; }
        if (balloon.y > balloonsCanvas.height - 57.5) { balloon.dy = -balloon.dy; balloon.y = balloonsCanvas.height - 57.5; }


        if (Math.abs(gravity.x) > 1) { balloon.dx += gravity.x * 0.02; }
        if (Math.abs(gravity.y) > 1) { balloon.dy -= gravity.y * 0.02; }

        if (Math.abs(acceleration.x) > 2) { balloon.dx += acceleration.x * 0.2; }
        if (Math.abs(acceleration.y) > 2) { balloon.dy -= acceleration.y * 0.3; }

       

       
    }

    // balloonsContext.strokeStyle = "#eeeeee";
    // balloonsContext.lineWidth = 2;
    // balloonsContext.beginPath();
    // balloonsContext.moveTo(balloonsCanvas.width /2, balloonsCanvas.height)
    // balloonsContext.lineTo(200, 220)
    // balloonsContext.stroke();


    window.requestAnimationFrame(() => {
        render();
    });

}



navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false
}).then(function (stream) {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.1;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        distortion = (Math.round(average) * 0.1);
        distortionMax = distortion > distortionMax ? distortion : distortionMax;
        document.querySelector(".mic-debug").innerHTML = `Mic: ${distortion.toFixed(2)} [${distortionMax.toFixed(2)}]`;
        // colorPids(average);
    };
}).catch(function (err) {
    /* handle the error */
    console.error(err);
});



const acceleration = new LinearAccelerationSensor({ frequency: 60 });
acceleration.addEventListener("reading", handleAcceleration);
acceleration.start();

const gravity = new GravitySensor({ frequency: 60 });
gravity.addEventListener("reading", handleGravity);
gravity.start();


const gyroscope = new Gyroscope({ frequency: 60 });
gyroscope.addEventListener("reading", handleGyroscope);
gyroscope.start();


darkness.addEventListener("click", e => {

    darkness.classList.add("darkness--closed")
    started = true;

    // sparklerSound.play();
})


render();

